import React, { useState } from 'react';
import {
  PaperContainer,
  Header,
  Badge,
  Title,
  Description,
  PrimaryButton,
  SecondaryButton,
} from './styled';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Additional imports for the form
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  Container,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import {
  ActionButtons,
  BlockTitle,
  Buttons,
  FormAction,
  FormField,
  OfferFormCol,
  OfferedText,
} from './styled';
import { useTranslation } from 'react-i18next';
import {
  getPrivateEventTypes,
  getBusinessEventTypes,
} from '../../utils/helper';
import { clientConfirmation } from '../../redux/email';

const validationSchema = Yup.object().shape({
  client_name: Yup.string().required('Required'),
  client_surname: Yup.string().required('Required'),
  client_number: Yup.number().when('client_type', {
    is: 'Company',
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('Required'),
  }),
  company_name: Yup.string().when('client_type', {
    is: 'Company',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  company_VAT_number: Yup.number().when('client_type', {
    is: 'Company',
    then: Yup.number().required('Required'),
    otherwise: Yup.number().notRequired(),
  }),
  company_number: Yup.number().when('client_type', {
    is: 'Company',
    then: Yup.number().required('Required'),
    otherwise: Yup.number().notRequired(),
  }),
  company_address: Yup.string().when('client_type', {
    is: 'Company',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  client_type: Yup.string().required('Required'),
  event_type: Yup.string().required('Required'),
});

const NextBestActionComponent = ({
  status,
  dispatch,
  tripRequestCode,
  busPartnerCode,
  loading = false,
  tripData,
}) => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle download logic
  const handleDownloadPDFClick = () => {
    if (!loading) {
      window
        .open(
          // eslint-disable-next-line no-undef
          `${process.env.REACT_APP_API_URL}/partner/${busPartnerCode}/trip-request/${tripRequestCode}/download-offer?lang=${i18n.language.toLowerCase()}`,
          '_blank',
        )
        .focus();
    }
  };

  let title, description;

  switch (status) {
    case 'offered':
      title = t('NextBestActions.offered.title');
      description = t('NextBestActions.offered.description');
      break;
    case 'accepted':
      title = t('NextBestActions.accepted.title');
      description = t('NextBestActions.accepted.description');
      break;
    case 'confirmed':
      title = t('NextBestActions.confirmed.title');
      description = t('NextBestActions.confirmed.description');
      break;
    case 'partpaid':
      title = t('NextBestActions.partpaid.title');
      description = t('NextBestActions.partpaid.description');
      break;
    case 'paid':
      title = t('NextBestActions.paid.title');
      description = t('NextBestActions.paid.description');
      break;
    default:
      return null; // No component for other statuses
  }

  return (
    <PaperContainer elevation={0}>
      <Header>
        <Badge>
          <OfflineBoltOutlinedIcon /> {t('NextBestActions.title')}
        </Badge>
      </Header>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {status === 'offered' && (
        <>
          <PrimaryButton
            variant="contained"
            color="primary"
            onClick={handleOpen}>
            {t('TripDetail.AcceptOfferBtn')}
          </PrimaryButton>
          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            {/* We now render the TripRequestWhenOffered form here */}
            <TripRequestWhenOffered
              tripData={tripData}
              tripRequestCode={tripRequestCode}
              busPartnerCode={busPartnerCode}
              loading={loading}
              dispatch={dispatch}
              handleClose={handleClose}
            />
          </Dialog>
        </>
      )}
      <SecondaryButton
        variant="contained"
        color="secondary"
        onClick={handleDownloadPDFClick}
        disabled={loading}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          t('TripDetail.DownloadOffer')
        )}
      </SecondaryButton>
    </PaperContainer>
  );
};

export default NextBestActionComponent;

/////////////////////////////////////////
// TripRequestWhenOffered Component
/////////////////////////////////////////

const TripRequestWhenOffered = ({
  tripData,
  tripRequestCode,
  busPartnerCode,
  loading,
  dispatch,
  handleClose,
}) => {
  const { t } = useTranslation();
  const eventTypesPrivate = getPrivateEventTypes();
  const eventTypesCompany = getBusinessEventTypes();
  const [clientType, setClientType] = useState(tripData?.client?.client_type);
  const initialValues = {
    client_name: '',
    client_surname: '',
    company_name: '',
    company_VAT_number: '',
    company_number: '',
    company_address: '',
    event_type: '',
    client_type: tripData?.client?.client_type || '',
    client_number: '',
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: '',
    type: 'success',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // Tracks if form was submitted

  const handleUpdateConfig = async (values) => {
    const payload = {
      ...values,
      client_number: values.client_number?.toString() || '',
      company_number: values.company_number?.toString() || '',
      requestId: tripRequestCode,
    };
    try {
      await dispatch(clientConfirmation(payload)); // Dispatch the action
      setAlertMsg({
        msg: 'Preventivo accettato con successo',
        type: 'success',
      }); // Set success message
      setOpenSnackbar(true); // Open Snackbar
      setIsSubmitted(true); // Mark as submitted
    } catch (error) {
      console.error('Error submitting the form:', error);
      setAlertMsg({ msg: t('TripDetail.ErrorMessage'), type: 'error' }); // Set error message
      setOpenSnackbar(true); // Open Snackbar
    }
  };

  const handleModalClose = () => {
    if (isSubmitted) {
      window.location.reload(); // Reload the page only if the form was submitted
    }
    handleClose(); // Close the modal
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleUpdateConfig}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        setFieldError,
      }) => (
        <Form noValidate onSubmit={handleSubmit} autoComplete="off">
          <Container maxWidth="md" style={{ padding: '30px' }}>
            <IconButton
              style={{
                position: 'absolute',
                top: '15px',
                right: '15px',
                zIndex: 1000,
              }}
              onClick={handleModalClose}
              aria-label={t('Close')}>
              <CloseOutlinedIcon />{' '}
            </IconButton>
            <BlockTitle>{t('TripDetail.AcceptOfferBtn')}</BlockTitle>
            <OfferedText>
              <Typography component="h3">
                {t('TripDetail.AcceptOfferTitle')}
              </Typography>
              <Typography component="p">
                {t('TripDetail.AcceptOfferBinding')}
              </Typography>
            </OfferedText>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 1, md: 3 }}>
              <Grid
                item
                xs={6}
                md={3}
                className={
                  touched.client_name && errors.client_name
                    ? 'fieldHasError'
                    : ''
                }>
                <FormField>
                  <Typography className="tripBoxLabel">
                    <span>{t('TripDetail.Name')}</span>
                  </Typography>
                  <OfferFormCol>
                    <TextField
                      placeholder={t('TripDetail.Name')}
                      error={!!(touched.client_name && errors.client_name)}
                      helperText={touched.client_name && errors.client_name}
                      type="text"
                      name="client_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.client_name}
                    />
                  </OfferFormCol>
                </FormField>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                className={
                  touched.client_surname && errors.client_surname
                    ? 'fieldHasError'
                    : ''
                }>
                <FormField>
                  <Typography className="tripBoxLabel">
                    <span>{t('TripDetail.Surname')}</span>
                  </Typography>
                  <OfferFormCol>
                    <TextField
                      placeholder={t('TripDetail.Surname')}
                      error={
                        !!(touched.client_surname && errors.client_surname)
                      }
                      helperText={
                        touched.client_surname && errors.client_surname
                      }
                      type="text"
                      name="client_surname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.client_surname}
                    />
                  </OfferFormCol>
                </FormField>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                className={
                  (clientType === 'Company' &&
                    touched.company_number &&
                    errors.company_number) ||
                  (clientType !== 'Company' &&
                    touched.client_number &&
                    errors.client_number)
                    ? 'fieldHasError'
                    : ''
                }>
                <FormField>
                  <Typography className="tripBoxLabel">
                    <span>{t('TripDetail.Telephone')}</span>
                  </Typography>
                  {clientType == 'Company' ? (
                    <OfferFormCol>
                      <TextField
                        placeholder={t('TripDetail.Telephone')}
                        error={
                          !!(touched.company_number && errors.company_number)
                        }
                        helperText={
                          touched.company_number && errors.company_number
                        }
                        type="number"
                        name="company_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company_number}
                        onKeyDown={(e) => {
                          if (['e', 'E', '-'].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </OfferFormCol>
                  ) : (
                    <OfferFormCol>
                      <TextField
                        placeholder={t('TripDetail.Telephone')}
                        error={
                          !!(touched.client_number && errors.client_number)
                        }
                        helperText={
                          touched.client_number && errors.client_number
                        }
                        type="number"
                        name="client_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.client_number}
                        onKeyDown={(e) => {
                          if (['e', 'E', '-'].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </OfferFormCol>
                  )}
                </FormField>
              </Grid>
              {/* <Grid item xs={12} md={6} className="tripBoxCol">
                  <Typography>
                    <span>{t('TripDetail.Email')}: </span>
                    {tripData?.client?.requestor_email}
                  </Typography>
                </Grid> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              className={
                touched.client_type && errors.client_type ? 'fieldHasError' : ''
              }>
              <FormField>
                <Typography className="tripBoxLabel">
                  <span>{t('TripDetail.ClientType')}</span>
                </Typography>
                <OfferFormCol>
                  <RadioGroup
                    name="client_type"
                    value={values.client_type}
                    onChange={(e) => {
                      handleChange(e);
                      setClientType(e.target.value);
                      setFieldValue('event_type', '');
                      setFieldTouched('event_type', false);
                      setFieldError('event_type', '');
                    }}
                    row>
                    <FormControlLabel
                      value="Private"
                      control={<Radio />}
                      label={t(`ClientTypes.Private`)}
                      checked={values.client_type === 'Private'}
                    />
                    <FormControlLabel
                      value="Company"
                      control={<Radio />}
                      label={t(`ClientTypes.Company`)}
                      checked={values.client_type === 'Company'}
                    />
                  </RadioGroup>
                </OfferFormCol>
              </FormField>
            </Grid>
            {clientType == 'Company' && (
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="companytrip">
                <Grid
                  item
                  xs={6}
                  md={3}
                  className={
                    touched.company_name && errors.company_name
                      ? 'fieldHasError'
                      : ''
                  }>
                  <FormField>
                    <Typography className="tripBoxLabel">
                      <span>{t('TripDetail.CompanyName')}</span>
                    </Typography>
                    <OfferFormCol>
                      <TextField
                        placeholder={t('TripDetail.CompanyName')}
                        error={!!(touched.company_name && errors.company_name)}
                        helperText={touched.company_name && errors.company_name}
                        type="text"
                        name="company_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company_name}
                      />
                    </OfferFormCol>
                  </FormField>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  className={
                    touched.company_VAT_number && errors.company_VAT_number
                      ? 'fieldHasError'
                      : ''
                  }>
                  <FormField>
                    <Typography className="tripBoxLabel">
                      <span>{t('TripDetail.VATNumber')}</span>
                    </Typography>
                    <OfferFormCol>
                      <TextField
                        placeholder={t('TripDetail.VATNumber')}
                        error={
                          !!(
                            touched.company_VAT_number &&
                            errors.company_VAT_number
                          )
                        }
                        helperText={
                          touched.company_VAT_number &&
                          errors.company_VAT_number
                        }
                        type="text"
                        name="company_VAT_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company_VAT_number}
                        onKeyDown={(e) => {
                          if (['e', 'E', '-', '+'].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </OfferFormCol>
                  </FormField>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  className={
                    touched.company_address && errors.company_address
                      ? 'fieldHasError'
                      : ''
                  }>
                  <FormField>
                    <Typography className="tripBoxLabel">
                      <span>{t('TripDetail.Address')}</span>
                    </Typography>
                    <OfferFormCol>
                      <TextField
                        placeholder={t('TripDetail.Address')}
                        error={
                          !!(touched.company_address && errors.company_address)
                        }
                        helperText={
                          touched.company_address && errors.company_address
                        }
                        type="text"
                        name="company_address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company_address}
                      />
                    </OfferFormCol>
                  </FormField>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {/* <Grid item xs={12} md={6} className="summeryField">
                  <Typography>
                    <span>{t('TripDetail.NoPass')}: </span>
                    {tripData?.request?.passenger_number}
                  </Typography>
                </Grid> */}
              <Grid item xs={12} md={12} className="summeryField">
                <FormField
                  className={
                    touched.event_type && errors.event_type
                      ? ' fieldHasError'
                      : ''
                  }>
                  <Typography className="eventTypeText">
                    <span>{t('TripDetail.EventType')}</span>
                  </Typography>
                  <OfferFormCol className="eventTypeSelect">
                    <Select
                      sx={{ width: '50%' }}
                      name="event_type"
                      displayEmpty
                      value={values.event_type}
                      input={<OutlinedInput />}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(touched.event_type && errors.event_type)}>
                      <MenuItem disabled value="">
                        {t('TripDetail.EventType')}
                      </MenuItem>
                      {clientType == 'Company' &&
                        eventTypesCompany.map((name) => (
                          <MenuItem key={name} value={name}>
                            {t(`EventTypes.${name}`)}
                          </MenuItem>
                        ))}
                      {clientType == 'Private' &&
                        eventTypesPrivate.map((name) => (
                          <MenuItem key={name} value={name}>
                            {t(`EventTypes.${name}`)}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText className="Mui-error">
                      {touched.event_type && errors.event_type}
                    </FormHelperText>
                  </OfferFormCol>
                </FormField>
              </Grid>
              {tripData?.request?.additional_information && (
                <Grid item xs={12} md={12} className="summeryField">
                  <Typography>
                    <span>{t('TripDetail.AdditionInfoCustomer')}: </span>
                    {tripData?.request?.additional_information}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {tripData?.request?.status === 'offered' && (
              <FormAction>
                <ActionButtons>
                  <Buttons>
                    <SecondaryButton
                      variant="outlined"
                      className="cancel-button"
                      onClick={handleModalClose}>
                      {t('TripDetail.CancelBtn')}
                    </SecondaryButton>
                    <PrimaryButton
                      variant="contained"
                      className="green"
                      type="submit">
                      {loading && (
                        <CircularProgress
                          color="warning"
                          size={24}
                          thickness={5}
                        />
                      )}
                      {t('TripDetail.AcceptOfferBtn')}
                    </PrimaryButton>
                  </Buttons>
                </ActionButtons>
              </FormAction>
            )}
          </Container>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={() => setOpenSnackbar(false)}>
            <Alert severity={alertMsg.type} sx={{ width: '100%' }}>
              {alertMsg.msg}
            </Alert>
          </Snackbar>
        </Form>
      )}
    </Formik>
  );
};
