import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormGroup,
  FormHelperText,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  FormBox,
  Heading,
  PartnerFormWrapp,
  SubmitButton,
  SubmitWrapper,
  TermsConditions,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RightArrow } from '../../assets/icon';
import { getBusinessEventTypes } from '../../utils/helper';
import { clientConfirmation } from '../../redux/email';

const CompanyInformation = ({ requestId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.email);
  const eventTypes = getBusinessEventTypes();
  const initialValues = {
    client_name: '',
    client_surname: '',
    company_name: '',
    company_VAT_number: '',
    company_number: '',
    company_address: '',
    event_type: '',
  };
  const validationSchema = Yup.object().shape({
    client_name: Yup.string().required('Required'),
    client_surname: Yup.string().required('Required'),
    company_name: Yup.string().required('Required'),
    company_VAT_number: Yup.number().required('Required'),
    company_number: Yup.number().required('Required'),
    company_address: Yup.string().required('Required'),
    event_type: Yup.string().required('Required'),
  });
  const handleUpdateConfig = (values) => {
    const { company_number, ...restValues } = values;
    const payload = {
      ...restValues,

      company_number: company_number.toString(),
      requestId,
    };
    dispatch(clientConfirmation(payload));
  };
  return (
    <Container maxWidth={false}>
      <Heading>{t('AddInfo.Title')}</Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdateConfig}
      >
        {({
          values,
          touched,
          errors,
          /* setFieldValue, */ handleChange,
          handleSubmit,
          handleBlur /* , submitForm */,
        }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <FormBox className="titleStyle">
              <Typography className="boxHeading">
                {t('AddInfo.InsertData')}
              </Typography>
              <PartnerFormWrapp>
                <FormGroup row className="formWrapp">
                  <TextField
                    sx={{ width: '100%' }}
                    placeholder={t('AddInfo.Name')}
                    error={!!(touched.client_name && errors.client_name)}
                    helperText={touched.client_name && errors.client_name}
                    type="text"
                    name="client_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.client_name}
                  />
                  <TextField
                    sx={{ width: '100%' }}
                    placeholder={t('AddInfo.Surname')}
                    error={!!(touched.client_surname && errors.client_surname)}
                    helperText={touched.client_surname && errors.client_surname}
                    type="text"
                    name="client_surname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.client_surname}
                  />
                </FormGroup>
                <FormGroup row className="formWrapp">
                  <TextField
                    sx={{ width: '100%' }}
                    placeholder={t('AddInfo.CompanyName')}
                    error={!!(touched.company_name && errors.company_name)}
                    helperText={touched.company_name && errors.company_name}
                    type="text"
                    name="company_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.company_name}
                  />
                  <TextField
                    sx={{ width: '100%' }}
                    placeholder={t('AddInfo.VATNumber')}
                    error={
                      !!(
                        touched.company_VAT_number && errors.company_VAT_number
                      )
                    }
                    helperText={
                      touched.company_VAT_number && errors.company_VAT_number
                    }
                    type="text"
                    name="company_VAT_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.company_VAT_number}
                    onKeyDown={(e) => {
                      if (
                        e.key === 'e' ||
                        e.key === 'E' ||
                        e.key === '-' ||
                        e.key === '+'
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup row className="formWrapp">
                  <TextField
                    sx={{ width: '100%' }}
                    placeholder={t('AddInfo.Telephone')}
                    error={!!(touched.company_number && errors.company_number)}
                    helperText={touched.company_number && errors.company_number}
                    type="number"
                    name="company_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.company_number}
                    onKeyDown={(e) => {
                      if (e.key === 'e' || e.key === 'E' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <TextField
                    sx={{ width: '100%' }}
                    placeholder={t('AddInfo.Address')}
                    error={
                      !!(touched.company_address && errors.company_address)
                    }
                    helperText={
                      touched.company_address && errors.company_address
                    }
                    type="text"
                    name="company_address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.company_address}
                  />
                </FormGroup>
                <FormGroup row className="formWrapp lastChild">
                  <FormControl sx={{ width: '100%' }}>
                    <Select
                      name="event_type"
                      displayEmpty
                      value={values.event_type}
                      input={<OutlinedInput />}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(touched.event_type && errors.event_type)}
                    >
                      <MenuItem disabled value="">
                        {t('AddInfo.EventType')}
                      </MenuItem>
                      {eventTypes.map((name) => (
                        <MenuItem key={name} value={name}>
                          {t(`EventTypes.${name}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.event_type && errors.event_type}
                    </FormHelperText>
                  </FormControl>
                  <Box sx={{ width: '100%' }}></Box>
                </FormGroup>
              </PartnerFormWrapp>
            </FormBox>
            <SubmitWrapper>
              <SubmitButton>
                {loading && (
                  <CircularProgress color="warning" size={24} thickness={5} />
                )}
                <Button type="submit" variant="contained">
                  {t('AddInfo.AcceptQuote')}
                  <RightArrow />
                </Button>
              </SubmitButton>
              <TermsConditions>
                <Typography sx={{ textAlign: 'center' }}>
                  {t('AddInfo.AcceptPPTC')
                    .split('[PrivacyPolicy]')
                    .map((part, index) =>
                      index !== 0 ? (
                        <React.Fragment key={index}>
                          <Link
                            target="_blank"
                            href="privacy-policy"
                            color="primary"
                            rel="noopener noreferrer"
                          >
                            {t('AddInfo.PrivacyPolicy')}
                          </Link>
                          {part
                            .split('[TermsConditions]')
                            .map((part2, index2) =>
                              index2 !== 0 ? (
                                <React.Fragment key={index2}>
                                  <Link
                                    target="_blank"
                                    href="terms-conditions"
                                    color="primary"
                                    rel="noopener noreferrer"
                                  >
                                    {t('AddInfo.TermsConditions')}
                                  </Link>
                                  {part2}
                                </React.Fragment>
                              ) : (
                                part2
                              ),
                            )}
                        </React.Fragment>
                      ) : (
                        part
                      ),
                    )}
                </Typography>
              </TermsConditions>
            </SubmitWrapper>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CompanyInformation;
