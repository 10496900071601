import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from './auth/slice';
import managementReducer from './management/slice';
import depotReducer from './depot/slice';
import companyReducer from './company/slice';
import pricecalcReducer from './pricecalc/slice';
import reqhistoryReducer from './reqhistory/slice';
import emailReducer from './email/slice';

const reducers = combineReducers({
  auth: authReducer,
  management: managementReducer,
  depot: depotReducer,
  company: companyReducer,
  pricecalc: pricecalcReducer,
  reqhistory: reqhistoryReducer,
  email: emailReducer,
});
const rootReducer = (state, action) => reducers(state, action);
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['company'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);
