import { createSlice } from '@reduxjs/toolkit';
import {
  getArrivalTime,
  getArrivalTimeReturn,
  postPriceCalculation,
} from '../pricecalc';

const initialState = {
  loading: false,
  priceCalculationDetail: {},
  priceCalculationError: '',
  arrivalTimeDetail: {},
  arrivalTimeError: '',
  arrivalTimeDetailReturn: {},
  arrivalTimeErrorReturn: '',
};

const pricecalcSlice = createSlice({
  name: 'pricecalc',
  initialState,
  reducers: {
    reset: () => initialState,
    resetPriceCalculation: (state) => {
      state.priceCalculationDetail = {};
      state.priceCalculationError = '';
    },
    resetArrivalTime: (state) => {
      state.arrivalTimeDetail = {};
      state.arrivalTimeError = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postPriceCalculation.pending, (state) => {
      state.loading = true;
      state.priceCalculationDetail = {};
      state.priceCalculationError = '';
    });
    builder.addCase(postPriceCalculation.fulfilled, (state, action) => {
      state.loading = false;
      state.priceCalculationDetail = action.payload?.response?.data;
    });
    builder.addCase(postPriceCalculation.rejected, (state, err) => {
      state.loading = false;
      state.priceCalculationError = err?.payload;
    });

    builder.addCase(getArrivalTime.pending, (state) => {
      state.loading = true;
      state.arrivalTimeDetail = {};
      state.arrivalTimeError = '';
    });
    builder.addCase(getArrivalTime.fulfilled, (state, action) => {
      state.loading = false;
      state.arrivalTimeDetail = action.payload?.response?.data?.data;
    });
    builder.addCase(getArrivalTime.rejected, (state, err) => {
      state.loading = false;
      state.arrivalTimeError = err?.payload;
    });

    builder.addCase(getArrivalTimeReturn.pending, (state) => {
      state.loading = true;
      state.arrivalTimeDetailReturn = {};
      state.arrivalTimeErrorReturn = '';
    });
    builder.addCase(getArrivalTimeReturn.fulfilled, (state, action) => {
      state.loading = false;
      state.arrivalTimeDetailReturn = action.payload?.response?.data?.data;
    });
    builder.addCase(getArrivalTimeReturn.rejected, (state, err) => {
      state.loading = false;
      state.arrivalTimeErrorReturn = err?.payload;
    });
  },
});

export const { reset, resetPriceCalculation, resetArrivalTime } =
  pricecalcSlice.actions;

export default pricecalcSlice.reducer;
