import { createSlice } from '@reduxjs/toolkit';
import {
  downloadTripPDF,
  getBusPartnerDetail,
  getPaymentLink,
  getTripDetail,
} from '../company';

const initialState = {
  loading: false,
  company: [],
  companyError: '',
  tripData: {},
  tripDataError: '',
  paymentLink: {},
  paymentLinkError: '',
  downloadTripPDFData: {},
  downloadTripPDFError: '',
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    reset: () => initialState,
    resetCompany: (state) => {
      state.company = [];
      state.companyError = '';
    },
    resetPaymentLink: (state) => {
      state.paymentLink = {};
      state.paymentLinkError = '';
    },
    resetdownloadPDFFile: (state) => {
      state.downloadTripPDFData = {};
      state.downloadTripPDFError = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusPartnerDetail.pending, (state) => {
      state.loading = true;
      state.company = [];
      state.companyError = '';
    });
    builder.addCase(getBusPartnerDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.company = action.payload?.response.data.data;
    });
    builder.addCase(getBusPartnerDetail.rejected, (state, err) => {
      state.loading = false;
      state.companyError = err?.payload?.message;
    });

    builder.addCase(getTripDetail.pending, (state) => {
      state.loading = true;
      state.tripData = {};
      state.tripDataError = '';
    });
    builder.addCase(getTripDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.tripData = action.payload?.response.data.data;
    });
    builder.addCase(getTripDetail.rejected, (state, err) => {
      state.loading = false;
      state.tripDataError = err?.payload?.message;
    });

    builder.addCase(getPaymentLink.pending, (state) => {
      state.loading = true;
      state.paymentLink = {};
      state.paymentLinkError = '';
    });
    builder.addCase(getPaymentLink.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentLink = action.payload?.response.data.data;
    });
    builder.addCase(getPaymentLink.rejected, (state, err) => {
      state.loading = false;
      state.paymentLinkError = err?.payload?.message;
    });

    builder.addCase(downloadTripPDF.pending, (state) => {
      state.loading = true;
      state.downloadTripPDFData = {};
      state.downloadTripPDFDataError = '';
    });
    builder.addCase(downloadTripPDF.fulfilled, (state, action) => {
      state.loading = false;
      state.downloadTripPDFData = action.payload?.response.data;
    });
    builder.addCase(downloadTripPDF.rejected, (state, err) => {
      state.loading = false;
      state.downloadTripPDFDataError = err?.payload?.message;
    });
  },
});

export const { reset, resetCompany, resetPaymentLink, resetdownloadPDFFile } =
  companySlice.actions;

export default companySlice.reducer;
