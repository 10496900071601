import axios from 'axios';

/*eslint no-undef: "warn"*/

export function unAuthenticatedInstance() {
  return axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.REACT_APP_API_URL,
  });
}

export function authenticatedInstance(isAdmin, params) {
  const headers = {
    Authorization: `Bearer ${isAdmin ? localStorage.getItem('authTokenAdmin') : localStorage.getItem('authToken') || localStorage.getItem('authTokenAdmin')}`,
  };

  return axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.REACT_APP_API_URL,
    headers: headers,
    params,
  });
}

export function callPostAPI({
  route,
  body,
  isAuthenticated = true,
  isAdmin = false,
}) {
  if (isAuthenticated) {
    const requestInstance = authenticatedInstance(isAdmin);
    return requestInstance.post(route, body);
  }
  const requestInstance = unAuthenticatedInstance();
  return requestInstance.post(route, body);
}

export function callGetAPI({
  route,
  isAuthenticated = true,
  isAdmin = false,
  params = null,
}) {
  if (isAuthenticated) {
    const requestInstance = authenticatedInstance(isAdmin, params);
    return requestInstance.get(route);
  }
  const requestInstance = unAuthenticatedInstance();
  return requestInstance.get(route);
}

const newApiAxios = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_NEW_API_URL,
});

const getAuthHeaders = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem('authCustomerToken')}`,
  };
};

export function authGet({ route, isAdmin }) {
  return newApiAxios.get(route, { headers: { ...getAuthHeaders(isAdmin) } });
}

export function authPost({ route, body, isAdmin }) {
  return newApiAxios.post(route, body, {
    headers: { ...getAuthHeaders(isAdmin) },
  });
}

// SignUp
export function registerInstance() {
  return axios.create({
    baseURL: 'https://reqres.in/api/register',
  });
}

export function userSignUp({ route, body }) {
  const requestSignUp = registerInstance();
  return requestSignUp.post(route, body);
}
