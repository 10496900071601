import React from 'react';
import { Container } from '@mui/material';

const PrivacyPolicyPage = () => {
  return (
    <Container>
      <p>
        <strong>
          Privacy Policy per l’utilizzo dei siti web di Quotabus SRL (di seguito
          &quot;Quotabus&quot;)
        </strong>
      </p>
      <p>
        <em>Ultimo aggiornamento: Marzo 11, 2024.</em>
      </p>
      <p>
        Quotabus è accessibile da{' '}
        <a href="http://www.quotabus.com/">
          <span style={{ color: '#1155cc' }}>
            <u>www.quotabus.com</u>
          </span>
        </a>{' '}
        e tutti i sottodomini associati. Una delle nostre principali priorità è
        la privacy dei nostri visitatori ed utilizzatori del servizio.
        <br />
        Questo documento sulla Politica sulla Privacy contiene le tipologie di
        informazioni che vengono raccolte e registrate da Quotabus SRL (di
        seguito “Quotabus”) e come vengono utilizzate.
        <br />
        Questa Politica sulla Privacy si applica solo alle nostre attività
        online ed è valida per i visitatori del nostro sito web riguardo alle
        informazioni che hanno condiviso e/o raccolto sui siti web di Quotabus.
        Questa politica non è applicabile a informazioni raccolte offline o
        tramite canali diversi da questo sito web.
      </p>
      <p>
        <strong>Consenso </strong>
      </p>
      <p>
        Utilizzando il nostro sito web, acconsentite alla nostra Politica sulla
        Privacy e accettate i suoi termini.
      </p>
      <p>
        <strong>Informazioni collezionate</strong>
      </p>
      <p>
        Le informazioni personali che vi verranno richieste di fornire, e le
        ragioni per cui vi viene chiesto di fornirle, vi saranno chiaramente
        spiegate nel momento in cui vi chiederemo di fornire le vostre
        informazioni personali.
      </p>
      <p>
        Se ci contattate direttamente, potremmo ricevere informazioni aggiuntive
        su di voi come il vostro nome, indirizzo email, numero di telefono, il
        contenuto del messaggio e/o allegati che potreste inviarci, e qualsiasi
        altra informazione che scegliete di fornire.
      </p>
      <p>
        Nel caso vi registriate per un Account con Quotabus, potremmo chiedervi
        informazioni di contatto, inclusi elementi come nome dell&apos;azienda,
        indirizzo dell’azienda, indirizzo email e numero di telefono,
        informazioni sulla vostra flotta, informazioni sulla vostra prezzistica
        ed altro.
      </p>
      <p>
        <strong>Come utilizziamo le vostre informationi</strong>
      </p>
      <p>Utilizziamo le informazioni raccolte in vari modi per:</p>
      <ul>
        <li>
          <p>Fornire, gestire e mantenere il nostro sito web.</p>
        </li>
        <li>
          <p>Migliorare, personalizzare ed espandere il nostro sito web.</p>
        </li>
        <li>
          <p>Comprendere e analizzare come utilizzate il nostro sito web.</p>
        </li>
        <li>
          <p>Sviluppare nuovi prodotti, servizi, funzionalità e funzioni.</p>
        </li>
        <li>
          <p>
            Comunicare con voi, direttamente o tramite uno dei nostri partner,
            incluso per il servizio clienti, per fornirvi aggiornamenti e altre
            informazioni relative al sito web, e per scopi di marketing e
            promozionali.
          </p>
        </li>
        <li>
          <p>Inviare email.</p>
        </li>
        <li>
          <p>Trovare e prevenire frodi.</p>
        </li>
        <li>
          <p>Registrazione al sito e fruizione dei servizi;</p>
        </li>
        <li>
          <p>Gestione delle vostre richieste.</p>
        </li>
      </ul>
      <p>
        Le informazioni ed i dati acquisiti da Quotabus verranno conservati per
        il tempo strettamente necessario a conseguire gli scopi per cui sono
        stati raccolti, compresa la condivisione di tali informazioni con le
        aziende partner al fine di facilitare la prestazione dei servizi
        richiesti. Scaduto questo periodo, i dati saranno definitivamente
        cancellati o comunque resi anonimi in via irreversibile
      </p>
      <p>
        <strong>File di registro</strong>
      </p>
      <p>
        Il team di Quotabus segue una procedura standard nell&apos;uso dei file
        di registro. Questi file registrano i visitatori quando visitano i siti
        web. Le aziende di hosting fanno ciò come parte dell&apos;analisi dei
        servizi di hosting. Le informazioni raccolte dai file di registro
        includono gli indirizzi di protocollo internet (IP), il tipo di browser,
        il Provider di Servizi Internet (ISP), la data e l&apos;ora, le pagine
        di riferimento/uscita e possibilmente il numero di clic. Queste non sono
        collegate a informazioni che possono identificare personalmente. Lo
        scopo delle informazioni è per analizzare le tendenze, amministrare il
        sito, tracciare il movimento degli utenti sul sito web e raccogliere
        informazioni demografiche.
      </p>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        Come accade con molti altri siti web, anche il team di Quotabus impiega
        l&apos;uso dei &quot;cookies&quot;. Questi cookies sono utilizzati per
        raccogliere informazioni che includono le preferenze dei visitatori e le
        pagine del sito web visitate o a cui hanno avuto accesso. Queste
        informazioni servono a migliorare l&apos;esperienza degli utenti,
        adattando il contenuto delle nostre pagine web in base al tipo di
        browser utilizzato dai visitatori e ad altre informazioni pertinenti. È
        importante sottolineare che queste informazioni raccolte non sono
        destinate all&apos;associazione con utenti specifici identificabili.
        Tuttavia, per loro natura, potrebbero permettere l&apos;identificazione
        degli utenti attraverso l&apos;elaborazione e l&apos;associazione con
        dati detenuti da terzi.
      </p>
      <p>
        Tra i dati raccolti in questa categoria rientrano gli indirizzi IP o i
        nomi a dominio dei computer utilizzati dagli utenti che si connettono al
        sito, oltre ad altri parametri legati al sistema operativo e
        all&apos;ambiente informatico utilizzato dall&apos;utente.
      </p>
      <p>
        <strong>Politiche sulla Privacy di Terze Parti</strong>
      </p>
      <p>
        La Politica sulla Privacy di Quotabus non si applica ad altri
        inserzionisti o siti web. Pertanto, vi consigliamo di consultare le
        rispettive Politiche sulla Privacy di questi server di annunci di terze
        parti per informazioni più dettagliate, ove applicabile. Ciò può
        includere le loro pratiche e istruzioni su come disattivare alcune
        opzioni.
        <br />
        Potete scegliere di disabilitare i cookies attraverso le opzioni del
        vostro browser. Per saperne di più su come gestire i cookies con
        specifici web browser, potete trovare informazioni nei rispettivi siti
        web dei browser.
      </p>
      <p>
        <strong>Diritti di Protezione dei Dati - GDPR</strong>
      </p>
      <p>
        Vogliamo assicurarci che gli utenti siano pienamente consapevoli di
        tutti i loro diritti di protezione dei dati. In particolare, ogni utente
        ha diritto a quanto segue:
      </p>
      <ul>
        <li>
          <p>
            <em>Il diritto di accesso</em> &ndash; Avete il diritto di
            richiedere copie dei vostri dati personali.
          </p>
        </li>
        <li>
          <p>
            <em>Il diritto di rettifica</em> &ndash; Avete il diritto di
            richiedere che correggiamo qualsiasi informazione che ritenete
            inaccurata. Avete anche il diritto di richiedere che completiamo le
            informazioni che ritenete incomplete.
          </p>
        </li>
        <li>
          <p>
            <em>Il diritto alla cancellazione (diritto all&rsquo;oblio)</em>{' '}
            &ndash; Avete il diritto di richiedere che cancelliamo i vostri dati
            personali, sotto certe condizioni.
          </p>
        </li>
        <li>
          <p>
            <em>Il diritto di limitare l&apos;elaborazione</em> &ndash; Avete il
            diritto di richiedere che limitiamo il processamento e
            l&rsquo;elaborazione dei vostri dati personali, sotto certe
            condizioni.
          </p>
        </li>
        <li>
          <p>
            <em>Il diritto di opporsi all&apos;elaborazione</em> &ndash; Avete
            il diritto di opporvi al nostro trattamento dei vostri dati
            personali, sotto certe condizioni.
          </p>
        </li>
        <li>
          <p>
            <em>Il diritto alla portabilit&agrave; dei dati</em> &ndash; Avete
            il diritto di richiedere che trasferiamo i dati che abbiamo raccolto
            ad un&apos;altra organizzazione, o direttamente a voi, sotto certe
            condizioni.
          </p>
        </li>
      </ul>
      <p>
        Se avete domande aggiuntive o richiedete ulteriori informazioni sulla
        nostra Politica sulla Privacy oppure volete esercitare i diritti di cui
        sopra, non esitate a contattarci a{' '}
        <a href="mailto:info@quotabus.com">
          <span style={{ color: '#1155cc' }}>
            <u>service@quotabus.com</u>
          </span>
        </a>
        . Una risposta sar&agrave; fornita entro massimo 30 giorni dalla
        richiesta.
      </p>
      <p>
        <strong>Informazioni su Minorenni</strong>
      </p>
      <p>
        Un&apos;altra nostra priorit&agrave; &egrave; aggiungere protezione per
        i minori durante l&apos;utilizzo di Internet. Incoraggiamo i genitori e
        i tutori a osservare, partecipare e/o monitorare e guidare la loro
        attivit&agrave; online.
      </p>
      Quotabus non raccoglie consapevolmente alcuna Informazione Personale
      Identificabile da minori (persone di et&agrave; inferiore ai 18 anni). Se
      pensate che vostro figlio/a abbia fornito questo tipo di informazioni sul
      nostro sito web, vi incoraggiamo vivamente a contattarci immediatamente e
      faremo del nostro meglio per rimuovere prontamente tali informazioni dai
      nostri archivi.
      <p>
        <strong>Diffusione dei dati</strong>
      </p>
      <p>
        I dati raccolti da Quotabus possono essere condivisi con i collaboratori
        o dipendenti per scopi legati alle loro mansioni specifiche, a
        condizione che siano autorizzati al trattamento di tali informazioni.
        Inoltre, i dati possono essere accessibili a soggetti esterni qualora vi
        sia una normativa che lo preveda. Questo include terzi come istituti
        bancari per la gestione dei pagamenti, enti fiscali e altre
        organizzazioni o enti pubblici per il rispetto di obblighi normativi. Si
        pu&ograve; includere anche la condivisione con eventuali intermediari
        coinvolti in qualsiasi aspetto del servizio fornito da Quotabus.
        Inoltre, i dati possono essere condivisi con soggetti terzi
        accuratamente selezionati, che dimostrano esperienza, competenza e
        affidabilit&agrave;, e che forniscono adeguate garanzie di rispetto
        delle normative vigenti in materia di trattamento dei dati, inclusa la
        sicurezza dei dati. Tali soggetti terzi operano in conformit&agrave;
        alle istruzioni fornite da Quotabus.
      </p>
      <p>
        Inoltre, Quotabus ha implementato adeguate misure di sicurezza per
        minimizzare i rischi di distruzione o perdita dei dati, anche in caso di
        incidenti, cos&igrave; come per prevenire l&apos;accesso non autorizzato
        o il trattamento non conforme alle finalit&agrave; per cui i dati sono
        stati raccolti. Queste misure sono state adottate per garantire un
        elevato livello di protezione e sicurezza dei dati gestiti, assicurando
        che siano trattati in maniera appropriata e nel rispetto delle
        finalit&agrave; specificate.
      </p>
      <p>
        Se avete domande aggiuntive o richiedete ulteriori informazioni sulla
        nostra Politica sulla Privacy oppure volete esercitare i diritti di cui
        sopra, non esitate a contattarci a{' '}
        <a href="mailto:info@quotabus.com">
          <span style={{ color: '#1155cc' }}>
            <u>service@quotabus.com</u>
          </span>
        </a>
        . Una risposta sar&agrave; fornita entro massimo 30 giorni dalla
        richiesta.
      </p>
    </Container>
  );
};

export default PrivacyPolicyPage;
