import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormGroup,
  FormHelperText,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  FormBox,
  Heading,
  PartnerFormWrapp,
  SubmitButton,
  SubmitWrapper,
  TermsConditions,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RightArrow } from '../../assets/icon';
import { getPrivateEventTypes } from '../../utils/helper';
import { clientConfirmation } from '../../redux/email';

const PrivateInformation = ({ requestId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.email);
  const eventTypes = getPrivateEventTypes();
  const initialValues = {
    client_name: '',
    client_surname: '',
    client_number: '',
    event_type: '',
  };
  const validationSchema = Yup.object().shape({
    client_name: Yup.string().required('Required'),
    client_surname: Yup.string().required('Required'),
    client_number: Yup.string().required('Required'),
    event_type: Yup.string().required('Required'),
  });
  const handleUpdateConfig = (values) => {
    const { client_number, ...restValues } = values;
    const payload = {
      ...restValues,

      client_number: client_number.toString(),
      requestId,
    };
    dispatch(clientConfirmation(payload));
  };
  return (
    <Container maxWidth={false}>
      <Heading>{t('AddInfo.Title')}</Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdateConfig}
      >
        {({
          values,
          touched,
          errors,
          /* setFieldValue, */ handleChange,
          handleSubmit,
          handleBlur /* , submitForm */,
        }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <FormBox className="titleStyle">
              <Typography className="boxHeading">
                {t('AddInfo.InsertData')}
              </Typography>
              <PartnerFormWrapp>
                <FormGroup row className="formWrapp">
                  <TextField
                    sx={{ width: '100%' }}
                    placeholder={t('AddInfo.Name')}
                    error={!!(touched.client_name && errors.client_name)}
                    helperText={touched.client_name && errors.client_name}
                    type="text"
                    name="client_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.client_name}
                  />
                  <TextField
                    sx={{ width: '100%' }}
                    placeholder={t('AddInfo.Surname')}
                    error={!!(touched.client_surname && errors.client_surname)}
                    helperText={touched.client_surname && errors.client_surname}
                    type="text"
                    name="client_surname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.client_surname}
                  />
                </FormGroup>
                <FormGroup row className="formWrapp">
                  <TextField
                    sx={{ width: '100%' }}
                    placeholder={t('AddInfo.Telephone')}
                    error={!!(touched.client_number && errors.client_number)}
                    helperText={touched.client_number && errors.client_number}
                    type="number"
                    name="client_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.client_number}
                    onKeyDown={(e) => {
                      if (e.key === 'e' || e.key === 'E' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <FormControl sx={{ width: '100%' }}>
                    <Select
                      name="event_type"
                      displayEmpty
                      value={values.event_type}
                      input={<OutlinedInput />}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(touched.event_type && errors.event_type)}
                    >
                      <MenuItem disabled value="">
                        {t('AddInfo.EventType')}
                      </MenuItem>
                      {eventTypes.map((name) => (
                        <MenuItem key={name} value={name}>
                          {t(`EventTypes.${name}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.event_type && errors.event_type}
                    </FormHelperText>
                  </FormControl>
                </FormGroup>
              </PartnerFormWrapp>
            </FormBox>
            <SubmitWrapper>
              <SubmitButton>
                {loading && (
                  <CircularProgress color="warning" size={24} thickness={5} />
                )}
                <Button type="submit" variant="contained">
                  {t('AddInfo.AcceptQuote')}
                  <RightArrow />
                </Button>
              </SubmitButton>
              <TermsConditions>
                <Typography>
                  {t('AddInfo.AcceptPPTC')
                    .split('[PrivacyPolicy]')
                    .map((part, index) =>
                      index !== 0 ? (
                        <React.Fragment key={index}>
                          <Link
                            target="_blank"
                            href="privacy-policy"
                            color="primary"
                            rel="noopener noreferrer"
                          >
                            {t('AddInfo.PrivacyPolicy')}
                          </Link>
                          {part
                            .split('[TermsConditions]')
                            .map((part2, index2) =>
                              index2 !== 0 ? (
                                <React.Fragment key={index2}>
                                  <Link
                                    target="_blank"
                                    href="terms-conditions"
                                    color="primary"
                                    rel="noopener noreferrer"
                                  >
                                    {t('AddInfo.TermsConditions')}
                                  </Link>
                                  {part2}
                                </React.Fragment>
                              ) : (
                                part2
                              ),
                            )}
                        </React.Fragment>
                      ) : (
                        part
                      ),
                    )}
                </Typography>
              </TermsConditions>
            </SubmitWrapper>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default PrivateInformation;
