import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/auth';

export const getBusPartnerDetail = createAsyncThunk(
  'getBusPartnerDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callGetAPI({
        route: 'bus-partner-details',
        params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const getTripDetail = createAsyncThunk(
  'getTripDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'request-details',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const getPaymentLink = createAsyncThunk(
  'getPaymentLink',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callGetAPI({ route: 'payment-url', params });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const downloadTripPDF = createAsyncThunk(
  'downloadTripPDF',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'download-offer',
        body: params,
        options: {
          responseType: 'blob',
        },
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
