import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const TripBox = styled.div`
  border-radius: 10px;
  background: #eef2ff;
  border: solid 1px #e0e7ff;
  padding: 15px 15px 20px;
  margin: 18px 0;
`;

export const Number = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: #cbd5e1;
`;

export const BoxHeading = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 15px 0;
  position: relative;
  span {
    color: ${theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -0.472px;
  }
  h3 {
    width: calc(100% - 48px);
    padding-left: 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.472px;
  }
`;

export const DeleteButton = styled.div`
  position: absolute;
  right: 0;
  width: 36px;
  height: 36px;
  border: 1px solid ${theme.colors.grey};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TripFeild = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  @media screen and (max-width: 1400px) {
    padding-left: 10px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 10px;
  }
`;

export const InputBox = styled.div`
  width: calc(50% - 32px);
  padding-right: 32px;
  border-radius: 6px;
  @media screen and (max-width: 767px) {
    width: calc(100% - 7px);
    padding-bottom: 10px;
  }
`;

export const LeftPart = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  @media screen and (max-width: 1199px) {
    width: 100%;
  }
    
  label {
    padding-left: 2px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: ${theme.colors.black};
    letter-spacing: -0.3px;
    @media screen and (max-width: 991px) {
      padding-left: 2px;
    }
  }
  .MuiTextField-root {
    width: 100%;
  }
  .Mui-disabled {
    cursor: not-allowed;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    padding-top: 5px;
    padding-bottom: 5px;
    background: ${theme.colors.white};
    input {
      padding: 9px 0 5px;
    }
  }
  .MuiFormControl-root {
    overflow: hidden;
    border-radius: 6px;
    .MuiOutlinedInput-root {
      padding: 5px 16px !important;
    }
  }
  fieldset {
    border-radius: 6px;
    border: 1px solid ${theme.colors.grey};
  }
  button.MuiAutocomplete-popupIndicator {
    display: none;
  }
`;

export const RightArrow = styled.div`
  position: absolute;
  right: calc(50% - 2px);
  z-index: 1;
  color: #64748b;
  width: 35px;
  height: 35px;
  border-radius: 23.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 27px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const RightPart = styled.div`
  width: calc(50% - 32px);
  padding-left: 32px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1199px) {
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
  }
`;

export const DateSelect = styled.div`
  width: 150px;
  @media screen and (max-width: 1199px) {
    width: calc(50% - 32px);
    margin-right: 7px;
  }
  .MuiInputBase-root {
    flex-direction: row-reverse;
  }
  .MuiFormControl-root {
    background: ${theme.colors.white};
    border-radius: 6px;
    @media screen and (max-width: 1199px) {
      width: 100%;
    }
  }
  button.MuiButtonBase-root {
    padding: 0 9px 0 0;
    position: unset;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: transparent;
      border-radius: 6px;
    }
    svg {
      fill: ${theme.colors.black};
    }
  }
  label {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: ${theme.colors.black};
    letter-spacing: -0.3px;
  }
  input {
    padding: 14px 0 10px 9px;
    color: ${theme.colors.black};
  }
  fieldset {
    border-radius: 6px;
    border: 1px solid ${theme.colors.grey};
  }
`;

export const TimeSelect = styled.div`
  width: 120px;
  padding-left: 18px;
  @media screen and (max-width: 1199px) {
    width: calc(50% - 7px);
    padding-left: 0;
    margin-right: 7px;
  }
  .MuiInputBase-root {
    flex-direction: row-reverse;
  }
  .MuiFormControl-root {
    background: ${theme.colors.white};
    border-radius: 6px;
    @media screen and (max-width: 1199px) {
      width: 100%;
    }
  }
  button.MuiButtonBase-root {
    padding: 0 9px 0 0;
    position: unset;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: transparent;
      border-radius: 6px;
    }
    svg {
      fill: ${theme.colors.black};
    }
  }
  label {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: ${theme.colors.black};
    letter-spacing: -0.3px;
  }
  input {
    padding: 14px 0 10px 9px;
    color: ${theme.colors.black};
  }
  fieldset {
    border-radius: 6px;
    border: 1px solid ${theme.colors.grey};
  }
`;
