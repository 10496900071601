import React, { useEffect, useState } from 'react';
import { indexOf, upperFirst, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import {
  DownloadIcon,
  Paynow,
  StepIcon,
  PendingIcon,
  CheckmarkIcon,
} from '../../assets/icon';
import {
  ActionButtons,
  BlockTitle,
  Buttons,
  CheckMarkRed,
  CurrentStepBg,
  ErrorMsg,
  FormAction,
  FormField,
  OfferFormCol,
  OfferedText,
  PriceNoteWrap,
  PriceWrap,
  SingleStep,
  StepContainerRow,
  StepWrapper,
  SummaryNote,
  SummeryRow,
  TripBox,
} from './styled';
import {
  formatItalianNumber,
  getBusinessEventTypes,
  getClientTypes,
  getDate,
  getPrivateEventTypes,
  getTime,
} from '../../utils/helper';
import {
  downloadTripPDF,
  getPaymentLink,
  getTripDetail,
} from '../../redux/company';
import {
  resetPaymentLink,
  resetdownloadPDFFile,
} from '../../redux/company/slice';
import { clientConfirmation } from '../../redux/email';
import { resetClientConfirm } from '../../redux/email/slice';
import ChatInterface from '../../components/ClientChat';
import NextBestActionComponent from '../../components/NextBestActionComponent';

const validationSchema = Yup.object().shape({
  client_name: Yup.string().required('Required'),
  client_surname: Yup.string().required('Required'),
  client_number: Yup.number().when('client_type', {
    is: 'Company',
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('Required'),
  }),
  company_name: Yup.string().when('client_type', {
    is: 'Company',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  company_VAT_number: Yup.number().when('client_type', {
    is: 'Company',
    then: Yup.number().required('Required'),
    otherwise: Yup.number().notRequired(),
  }),
  company_number: Yup.number().when('client_type', {
    is: 'Company',
    then: Yup.number().required('Required'),
    otherwise: Yup.number().notRequired(),
  }),
  company_address: Yup.string().when('client_type', {
    is: 'Company',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  client_type: Yup.string().required('Required'),
  event_type: Yup.string().required('Required'),
});

const statusList = [
  'Offered',
  'Accepted',
  'Confirmed',
  'Partpaid',
  'Paid',
  'Completed',
];

const TripDetail = () => {
  const { t } = useTranslation();
  const { tripRequestCode, busPartnerCode } = useParams();
  const {
    loading,
    tripData,
    paymentLink,
    downloadTripPDFData,
    downloadTripPDFDataError,
  } = useSelector((state) => state.company);
  const { clientConfirmDetail } = useSelector((state) => state.email);
  const [activeStatus, setActiveStatus] = useState(-1);
  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: '',
    type: 'success',
  });
  const downloadPDFURL = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isEmpty(tripRequestCode)) {
      dispatch(
        getTripDetail({
          trip_request_code: tripRequestCode,
          unique_code: busPartnerCode,
        }),
      );
    }
  }, [tripRequestCode]);
  useEffect(() => {
    if (!isEmpty(tripData) && tripData?.request?.status) {
      setActiveStatus(
        indexOf(statusList, upperFirst(tripData?.request?.status)) + 1,
      );
    }
  }, [tripData]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === 'visible' &&
        tripData?.request?.status !== 'offered'
      ) {
        window.location.reload();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      dispatch(resetClientConfirm());
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    if (!isEmpty(paymentLink) && paymentLink.url) {
      window.open(paymentLink.url, '_blank');
      dispatch(resetPaymentLink());
    }
  }, [paymentLink]);

  useEffect(() => {
    if (!isEmpty(downloadTripPDFData)) {
      downloadPDFURL(downloadTripPDFData?.data?.url);
      dispatch(resetdownloadPDFFile());
    }
    if (!isEmpty(downloadTripPDFDataError)) {
      setOpen(true);
      setAlertMsg({
        msg: downloadTripPDFDataError,
        type: 'error',
      });
    }
  }, [downloadTripPDFData, downloadTripPDFDataError]);
  useEffect(() => {
    if (clientConfirmDetail) {
      // navigate('/' + busPartnerCode + '/client-confirmed/' + tripRequestCode);
      navigate(
        '/' + busPartnerCode + '/offer/' + tripRequestCode + '/conversation',
      );
    }
  }, [clientConfirmDetail]);

  return (
    <Container maxWidth={false}>
      <SummeryRow>
        <Grid container rowSpacing={1} className="summeryWrap">
          <Grid item xs={12} sm={12} md={6}>
            <NextBestActionComponent
              status={tripData?.request?.status}
              dispatch={dispatch}
              tripRequestCode={tripRequestCode}
              busPartnerCode={busPartnerCode}
              loading={loading}
              tripData={tripData}
            />
            <TripDetailSummary tripData={tripData} />
            <TripItinerary tripData={tripData} />
            {tripData?.request?.status !== 'offered' && (
              <>
                <BlockTitle>{t('TripDetail.YourDetails')}</BlockTitle>
                <TripCustomerDataNotOffered tripData={tripData} />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ChatInterface />
          </Grid>
        </Grid>
      </SummeryRow>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={() => setOpen(false)}>
        <Alert severity={alertMsg.type} sx={{ width: '100%' }}>
          {alertMsg.msg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const TripDetailSummary = ({ tripData }) => {
  const { t } = useTranslation();
  return (
    <>
      <BlockTitle>{t('TripDetail.Summary')}</BlockTitle>
      <TripBox>
        <Grid container>
          <Grid item xs={12} sm={6} className="tripCol">
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="left"
              alignItems="left">
              <PriceWrap>
                <Typography variant="h4">{t('TripDetail.Price')}</Typography>
                <Typography variant="h2">
                  {formatItalianNumber(tripData?.price?.price)} €
                </Typography>
                <Typography>
                  <span
                    style={{
                      textAlign: 'left',
                      fontSize: '12px',
                      fontWeight: '400',
                    }}>
                    {tripData?.price?.include_vat
                      ? t('TripDetail.VATINCL', { vatper: 10 })
                      : t('TripDetail.VATEXCL', { vatper: 10 })}
                  </span>
                </Typography>
              </PriceWrap>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} className="tripCol">
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="left"
              alignItems="left">
              <PriceWrap>
                <Typography variant="h4" className="pl-0">
                  {t('TripDetail.RequestCode')}
                </Typography>
                <Typography variant="h2">
                  {tripData?.request?.trip_request_code}
                </Typography>
              </PriceWrap>
            </Stack>
          </Grid>
        </Grid>
      </TripBox>
    </>
  );
};

const TripItinerary = ({ tripData }) => {
  const { t } = useTranslation();

  return (
    <>
      <BlockTitle>{t('TripDetail.Itinerary')}</BlockTitle>
      <TripBox>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
          className="verticle_timeline_wrapper">
          {!isEmpty(tripData) &&
            tripData?.customer_segments.map((step, index) => (
              <TimelineItem key={index} className="verticle_timeline">
                <TimelineSeparator>
                  <TimelineDot sx={{ background: '#4f46e5' }}>
                    {/* {index + 1} */}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {/* tripData?.customer_segments[index - 1]['destination_stop']['arrival_datetime'] */}
                  {index !== 0 && (
                    <Typography>
                      {t('TripDetail.PointArrival', {
                        time: getTime(
                          tripData?.customer_segments[index - 1][
                            'destination_stop'
                          ]['arrival_datetime'],
                        ),
                        date: getDate(
                          tripData?.customer_segments[index - 1][
                            'destination_stop'
                          ]['arrival_datetime'],
                        ),
                      })}
                    </Typography>
                  )}

                  <Typography component="h3" className="steplabel">
                    {step?.departure_stop?.address}
                  </Typography>

                  <Typography>
                    {index === 0
                      ? t('TripDetail.StartDepature', {
                          time: getTime(
                            step?.departure_stop?.departure_datetime,
                          ),
                          date: getDate(
                            step?.departure_stop?.departure_datetime,
                          ),
                        })
                      : t('TripDetail.PointDepature', {
                          time: getTime(
                            step?.departure_stop?.departure_datetime,
                          ),
                          date: getDate(
                            step?.departure_stop?.departure_datetime,
                          ),
                        })}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          {!isEmpty(tripData) && tripData?.customer_segments && (
            <TimelineItem
              key={tripData?.customer_segments?.length + 1}
              className="verticle_timeline">
              <TimelineSeparator>
                <TimelineDot sx={{ background: '#4f46e5' }}>
                  {/* {tripData?.customer_segments?.length + 1} */}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography component="h3" className="steplabel">
                  {
                    tripData?.customer_segments[
                      tripData?.customer_segments?.length - 1
                    ]['destination_stop']['address']
                  }
                </Typography>
                <Typography>
                  {t('TripDetail.EndArrival', {
                    time: getTime(
                      tripData?.customer_segments[
                        tripData?.customer_segments?.length - 1
                      ]['destination_stop']['arrival_datetime'],
                    ),
                    date: getDate(
                      tripData?.customer_segments[
                        tripData?.customer_segments?.length - 1
                      ]['destination_stop']['arrival_datetime'],
                    ),
                  })}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          )}
        </Timeline>
      </TripBox>
    </>
  );
};

const TripCustomerDataNotOffered = ({ tripData }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <TripBox>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <Typography>
              <span>{t('TripDetail.Name')}: </span>
              {tripData?.client?.client_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <span>{t('TripDetail.Surname')}: </span>
              {tripData?.client?.client_surname}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <span>{t('TripDetail.Email')}: </span>
              {tripData?.client?.requestor_email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <span>{t('TripDetail.Telephone')}: </span>
              {tripData?.client?.client_type == 'Company'
                ? tripData?.client?.company_number
                : tripData?.client?.client_number}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <span>{t('TripDetail.ClientType')}: </span>
              {t(`ClientTypes.${tripData?.client?.client_type}`)}
            </Typography>
          </Grid>
        </Grid>
      </TripBox>
      {tripData?.client?.client_type == 'Company' && (
        <TripBox>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} md={6} className="summeryField">
              <Typography>
                <span>{t('TripDetail.CompanyName')}: </span>
                {tripData?.client?.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                <span>{t('TripDetail.VATNumber')}: </span>
                {tripData?.client?.company_vat_number}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} className="summeryField">
              <Typography>
                <span>{t('TripDetail.Address')}: </span>
                {tripData?.client?.company_address}
              </Typography>
            </Grid>
          </Grid>
        </TripBox>
      )}
      <TripBox>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6} className="summeryField">
            <Typography>
              <span>{t('TripDetail.NoPass')}: </span>
              {tripData?.request?.passenger_number}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="summeryField">
            <Typography>
              <span>{t('TripDetail.EventType')}: </span>
              {tripData?.request?.event_type
                ? t(`EventTypes.${tripData?.request?.event_type}`)
                : ''}
            </Typography>
          </Grid>
          {tripData?.request?.additional_information && (
            <Grid item xs={12} md={12} className="summeryField">
              <Typography>
                <span>{t('TripDetail.AdditionInfo')}: </span>
                {tripData?.request?.additional_information}
              </Typography>
            </Grid>
          )}
        </Grid>
      </TripBox>
    </React.Fragment>
  );
};

// const TripStatusSteps = ({
//   tripData,
//   activeStatus,
//   tripRequestCode,
//   busPartnerCode,
//   loading,
// }) => {
//   const { t } = useTranslation();

//   const dispatch = useDispatch();

//   const handlePayNow = () => {
//     dispatch(
//       getPaymentLink({
//         trip_request_code: tripRequestCode,
//         unique_code: busPartnerCode,
//       }),
//     );
//   };
//   const handleDownloadPDF = () => {
//     dispatch(
//       downloadTripPDF({
//         trip_request_code: tripRequestCode,
//         unique_code: busPartnerCode,
//       }),
//     );
//   };
//   const setPendingIcon = () => <PendingIcon />;
//   const setStepIcon = () => <StepIcon />;

//   return (
//     <>
//       <StepContainerRow>
//         <Grid container rowSpacing={1} className="stepRow">
//           <NextBestActionComponent
//             status={tripData?.request?.status}
//             dispatch={dispatch}
//             tripRequestCode={tripRequestCode}
//             busPartnerCode={busPartnerCode}
//             loading={loading}
//             tripData={tripData}
//           />
//           <Grid item xs={12} sm={12} md={6}>
//             <ActionButtons>
//               <Buttons>
//                 {tripData?.request?.pay_now && (
//                   <Button
//                     variant="contained"
//                     className="green"
//                     onClick={handlePayNow}>
//                     <Paynow />
//                     {t('TripDetail.PayNow')}
//                   </Button>
//                 )}
//               </Buttons>
//             </ActionButtons>
//           </Grid>
//         </Grid>
//       </StepContainerRow>
//     </>
//   );
// };

// const TripRequestWhenOffered = ({ tripRequestCode }) => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   const eventTypesPrivate = getPrivateEventTypes();
//   const eventTypesCompany = getBusinessEventTypes();
//   const { loading, tripData } = useSelector((state) => state.company);
//   const [clientType, setClientType] = useState(tripData?.client?.client_type);
//   const initialValues = {
//     client_name: '',
//     client_surname: '',
//     company_name: '',
//     company_VAT_number: '',
//     company_number: '',
//     company_address: '',
//     event_type: '',
//     client_type: tripData?.client?.client_type || '',
//     client_number: '',
//   };

//   const handleUpdateConfig = (values) => {
//     const payload = {
//       ...values,
//       client_number: values.client_number?.toString() || '',
//       company_number: values.company_number?.toString() || '',
//       requestId: tripRequestCode,
//     };
//     dispatch(clientConfirmation(payload));
//   };

//   return (
//     <Formik
//       enableReinitialize
//       initialValues={initialValues}
//       validationSchema={validationSchema}
//       onSubmit={handleUpdateConfig}>
//       {({
//         values,
//         touched,
//         errors,
//         setFieldValue,
//         setFieldTouched,
//         setFieldError,
//         handleChange,
//         handleSubmit,
//         handleBlur,
//       }) => (
//         <Form noValidate onSubmit={handleSubmit} autoComplete="off">
//           <TripBox>
//             <Grid
//               container
//               rowSpacing={3}
//               columnSpacing={{ xs: 1, sm: 1, md: 3 }}>
//               <Grid
//                 item
//                 xs={12}
//                 md={6}
//                 className={`tripBoxCol${touched.client_name && errors.client_name ? ' fieldHasError' : ''}`}>
//                 <FormField>
//                   <Typography className="tripBoxLabel">
//                     <span>{t('TripDetail.Name')}</span>
//                   </Typography>
//                   <OfferFormCol>
//                     <TextField
//                       placeholder={t('TripDetail.Name')}
//                       error={!!(touched.client_name && errors.client_name)}
//                       helperText={touched.client_name && errors.client_name}
//                       type="text"
//                       name="client_name"
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       value={values.client_name}
//                     />
//                   </OfferFormCol>
//                 </FormField>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 md={6}
//                 className={`tripBoxCol${touched.client_surname && errors.client_surname ? ' fieldHasError' : ''}`}>
//                 <FormField>
//                   <Typography className="tripBoxLabel">
//                     <span>{t('TripDetail.Surname')}</span>
//                   </Typography>
//                   <OfferFormCol>
//                     <TextField
//                       placeholder={t('TripDetail.Surname')}
//                       error={
//                         !!(touched.client_surname && errors.client_surname)
//                       }
//                       helperText={
//                         touched.client_surname && errors.client_surname
//                       }
//                       type="text"
//                       name="client_surname"
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       value={values?.client_surname}
//                     />
//                   </OfferFormCol>
//                 </FormField>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 md={6}
//                 className={`tripBoxCol${touched.client_type && errors.client_type ? ' fieldHasError' : ''}`}>
//                 <FormField FormField>
//                   <Typography className="tripBoxLabel">
//                     <span>{t('TripDetail.ClientType')}</span>
//                   </Typography>
//                   <OfferFormCol>
//                     <Select
//                       sx={{ width: '100%' }}
//                       name="client_type"
//                       displayEmpty
//                       value={values.client_type}
//                       input={<OutlinedInput />}
//                       onChange={(e) => {
//                         handleChange(e);
//                         setClientType(e.target.value);
//                         setFieldValue('event_type', '');
//                         setFieldTouched('event_type', false); // Reset touched state
//                         setFieldError('event_type', ''); // Clear error state
//                       }}
//                       onBlur={handleBlur}
//                       error={!!(touched.client_type && errors.client_type)}>
//                       {getClientTypes().map((name) => (
//                         <MenuItem key={name} value={name}>
//                           {t(`ClientTypes.${name}`)}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </OfferFormCol>
//                 </FormField>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 md={6}
//                 className={`tripBoxCol${(clientType === 'Company' && touched.company_number && errors.company_number) || (clientType !== 'Company' && touched.client_number && errors.client_number) ? ' fieldHasError' : ''}`}>
//                 <FormField>
//                   <Typography className="tripBoxLabel">
//                     <span>{t('TripDetail.Telephone')}</span>
//                   </Typography>
//                   {clientType == 'Company' ? (
//                     <OfferFormCol>
//                       <TextField
//                         placeholder={t('TripDetail.Telephone')}
//                         error={
//                           !!(touched.company_number && errors.company_number)
//                         }
//                         helperText={
//                           touched.company_number && errors.company_number
//                         }
//                         type="number"
//                         name="company_number"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values?.company_number}
//                         onKeyDown={(e) => {
//                           if (e.key === 'e' || e.key === 'E' || e.key === '-') {
//                             e.preventDefault();
//                           }
//                         }}
//                       />
//                     </OfferFormCol>
//                   ) : (
//                     <OfferFormCol>
//                       <TextField
//                         placeholder={t('TripDetail.Telephone')}
//                         error={
//                           !!(touched.client_number && errors.client_number)
//                         }
//                         helperText={
//                           touched.client_number && errors.client_number
//                         }
//                         type="number"
//                         name="client_number"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values?.client_number}
//                         onKeyDown={(e) => {
//                           if (e.key === 'e' || e.key === 'E' || e.key === '-') {
//                             e.preventDefault();
//                           }
//                         }}
//                       />
//                     </OfferFormCol>
//                   )}
//                 </FormField>
//               </Grid>
//               <Grid item xs={12} md={6} className="tripBoxCol">
//                 <Typography>
//                   <span>{t('TripDetail.Email')}: </span>{' '}
//                   {tripData?.client?.requestor_email}
//                 </Typography>
//               </Grid>
//             </Grid>
//           </TripBox>
//           {clientType == 'Company' && (
//             <TripBox>
//               <Grid
//                 container
//                 rowSpacing={3}
//                 columnSpacing={{ xs: 1, sm: 2, md: 3 }}
//                 className="companytrip">
//                 <Grid
//                   item
//                   xs={12}
//                   md={6}
//                   className={`tripBoxCol${touched.company_name && errors.company_name ? ' fieldHasError' : ''}`}>
//                   <FormField>
//                     <Typography className="tripBoxLabel">
//                       <span>{t('TripDetail.CompanyName')}</span>
//                     </Typography>
//                     <OfferFormCol>
//                       <TextField
//                         placeholder={t('TripDetail.CompanyName')}
//                         error={!!(touched.company_name && errors.company_name)}
//                         helperText={touched.company_name && errors.company_name}
//                         type="text"
//                         name="company_name"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values?.company_name}
//                       />
//                     </OfferFormCol>
//                   </FormField>
//                 </Grid>
//                 <Grid
//                   item
//                   xs={12}
//                   md={6}
//                   className={`tripBoxCol${touched.company_VAT_number && errors.company_VAT_number ? ' fieldHasError' : ''}`}>
//                   <FormField>
//                     <Typography className="tripBoxLabel">
//                       <span>{t('TripDetail.VATNumber')}</span>
//                     </Typography>
//                     <OfferFormCol>
//                       <TextField
//                         placeholder={t('TripDetail.VATNumber')}
//                         error={
//                           !!(
//                             touched.company_VAT_number &&
//                             errors.company_VAT_number
//                           )
//                         }
//                         helperText={
//                           touched.company_VAT_number &&
//                           errors.company_VAT_number
//                         }
//                         type="text"
//                         name="company_VAT_number"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values?.company_VAT_number}
//                         onKeyDown={(e) => {
//                           if (
//                             e.key === 'e' ||
//                             e.key === 'E' ||
//                             e.key === '-' ||
//                             e.key === '+'
//                           ) {
//                             e.preventDefault();
//                           }
//                         }}
//                       />
//                     </OfferFormCol>
//                   </FormField>
//                 </Grid>
//                 <Grid
//                   item
//                   xs={12}
//                   md={6}
//                   className={`tripBoxCol${touched.company_address && errors.company_address ? ' fieldHasError' : ''}`}>
//                   <FormField>
//                     <Typography className="tripBoxLabel">
//                       <span>{t('TripDetail.Address')}</span>
//                     </Typography>
//                     <OfferFormCol>
//                       <TextField
//                         placeholder={t('TripDetail.Address')}
//                         error={
//                           !!(touched.company_address && errors.company_address)
//                         }
//                         helperText={
//                           touched.company_address && errors.company_address
//                         }
//                         type="text"
//                         name="company_address"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values?.company_address}
//                       />
//                     </OfferFormCol>
//                   </FormField>
//                 </Grid>
//               </Grid>
//             </TripBox>
//           )}
//           <TripBox>
//             <Grid
//               container
//               rowSpacing={3}
//               columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
//               <Grid item xs={12} md={6} className="summeryField">
//                 <Typography>
//                   <span>{t('TripDetail.NoPass')}: </span>
//                   {tripData?.request?.passenger_number}
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} md={6} className="summeryField">
//                 <FormField
//                   className={
//                     touched.event_type && errors.event_type
//                       ? ' fieldHasError'
//                       : ''
//                   }>
//                   <Typography className="eventTypeText">
//                     <span>{t('TripDetail.EventType')}</span>
//                   </Typography>
//                   <OfferFormCol className="eventTypeSelect">
//                     <Select
//                       sx={{ width: '100%' }}
//                       name="event_type"
//                       displayEmpty
//                       value={values.event_type}
//                       input={<OutlinedInput />}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       error={!!(touched.event_type && errors.event_type)}>
//                       <MenuItem disabled value="">
//                         {t('TripDetail.EventType')}
//                       </MenuItem>
//                       {clientType == 'Company' &&
//                         eventTypesCompany.map((name) => (
//                           <MenuItem key={name} value={name}>
//                             {t(`EventTypes.${name}`)}
//                           </MenuItem>
//                         ))}
//                       {clientType == 'Private' &&
//                         eventTypesPrivate.map((name) => (
//                           <MenuItem key={name} value={name}>
//                             {t(`EventTypes.${name}`)}
//                           </MenuItem>
//                         ))}
//                     </Select>
//                     <FormHelperText className="Mui-error">
//                       {touched.event_type && errors.event_type}
//                     </FormHelperText>
//                   </OfferFormCol>
//                 </FormField>
//               </Grid>
//               <Grid item xs={12} md={12} className="summeryField">
//                 <Typography>
//                   <span>{t('TripDetail.AdditionInfoCustomer')}: </span>
//                   {tripData?.request?.additional_information}
//                 </Typography>
//               </Grid>
//             </Grid>
//           </TripBox>
//           {tripData?.request?.status === 'offered' && (
//             <FormAction>
//               <OfferedText>
//                 <Typography component="h3">
//                   {t('TripDetail.AcceptOfferTitle')}
//                 </Typography>
//                 <Typography component="p">
//                   {t('TripDetail.AcceptOfferBinding')}
//                 </Typography>
//               </OfferedText>

//               <ActionButtons>
//                 <Buttons>
//                   <Button variant="contained" className="green" type="submit">
//                     {loading && (
//                       <CircularProgress
//                         color="warning"
//                         size={24}
//                         thickness={5}
//                       />
//                     )}
//                     <CheckmarkIcon />
//                     {t('TripDetail.AcceptOfferBtn')}
//                   </Button>
//                 </Buttons>
//               </ActionButtons>
//             </FormAction>
//           )}
//         </Form>
//       )}
//     </Formik>
//   );
// };

export default TripDetail;
