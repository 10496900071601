import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import { Title, MainBox, CheckMark, HeadingWithIcon } from './styled.js';
import { CheckmarkIcon } from '../../assets/icon/index.js';

const PaymentSuccessCheckout = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <MainBox>
        <Title>
          <HeadingWithIcon>
            <CheckMark>
              <CheckmarkIcon />
            </CheckMark>
            <Typography variant="h1" component="h1">
              {t('PaymentSuccessCheckout.Title')}
            </Typography>
          </HeadingWithIcon>
          <Typography variant="h1" component="p">
            {t('PaymentSuccessCheckout.Detail')}
          </Typography>
        </Title>
      </MainBox>
    </Container>
  );
};

export default PaymentSuccessCheckout;
