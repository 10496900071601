import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/auth';

export const getHistory = createAsyncThunk(
  'getHistory',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callGetAPI({
        route: 'history-of-estimates',
        params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getHistoryDetail = createAsyncThunk(
  'getHistoryDetail',
  async (params, { rejectWithValue }) => {
    try {
      const { requestId, ...newParams } = params;
      const response = await callGetAPI({
        route: 'history-of-calculation/' + requestId,
        params: newParams,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getConversation = createAsyncThunk(
  'getConversation',
  async (params, { rejectWithValue }) => {
    try {
      const { requestId, ...newParams } = params;
      const response = await callGetAPI({
        route: 'history-of-calculation/' + requestId,
        params: newParams,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateRequestStatus = createAsyncThunk(
  'updateRequestStatus',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'update-trip-request-status',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
