import { createSlice } from '@reduxjs/toolkit';
import {
  ResetPassword,
  acceptTerms,
  forgotPassword,
  handleAdminLogOut,
  handleLogOut,
  handleLogin,
  handleSignup,
} from '../auth';

const initialState = {
  isLoggedIn: false,
  error: false,
  loading: false,
  loginResponse: {},
  busPartner: {},
  userDetails: {},
  forgotPasswordDetail: {},
  forgotPasswordError: '',
  resetPasswordDetail: {},
  resetPasswordError: '',
  acceptTermsDetail: {},
  acceptTermsError: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: () => initialState,
    loggedUserInfo: (state, { payload }) => {
      state.userDetails = payload;
      state.isLoggedIn = true;
      if (payload && payload?.role == 'BusPartner') {
        state.busPartner = payload;
        localStorage.setItem('authToken', payload.token);
      } else {
        state.busPartner = {};
        localStorage.setItem('authTokenAdmin', payload.token);
      }
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
    setBusPartner: (state, { payload }) => {
      state.busPartner = payload;
    },
    resetForgotPassword: (state) => {
      state.loading = false;
      state.forgotPasswordDetail = {};
      state.forgotPasswordError = '';
    },
    resetResetPassword: (state) => {
      state.loading = true;
      state.resetPasswordDetail = {};
      state.resetPasswordError = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleLogin.pending, (state) => {
      state.loading = true;
      state.loginErrorMessage = '';
    });
    builder.addCase(handleLogin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.loginResponse = payload.response;
      state.loginSuccessMessage = payload.response.data.message;
      state.loginErrorMessage = '';
      state.userDetails = payload.response.data.data;
      if (payload.response.data.data.role == 'BusPartner') {
        state.busPartner = payload.response.data.data;
        localStorage.setItem('authToken', payload.response.data.data.token);
      } else {
        state.busPartner = {};
        localStorage.setItem(
          'authTokenAdmin',
          payload.response.data.data.token,
        );
      }
    });
    builder.addCase(handleLogin.rejected, (state, err) => {
      state.error = true;
      state.loading = false;
      state.isLoggedIn = false;
      state.loginErrorMessage = err.payload?.message;
    });

    builder.addCase(handleLogOut.fulfilled, () => initialState);
    builder.addCase(handleAdminLogOut.fulfilled, () => initialState);

    builder.addCase(handleSignup.pending, (state) => {
      state.loading = true;
      state.loginErrorMessage = '';
    });
    builder.addCase(handleSignup.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.loginResponse = payload.response;
      state.loginSuccessMessage = payload.response.data.message;
      state.loginErrorMessage = '';
      state.userDetails = payload.response.data.data;
      localStorage.setItem('authToken', payload.response.data.data.token);
    });
    builder.addCase(handleSignup.rejected, (state, err) => {
      state.error = true;
      state.loading = false;
      state.loginErrorMessage = err.payload.message;
    });

    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
      state.forgotPasswordDetail = {};
      state.forgotPasswordError = '';
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.forgotPasswordDetail = action.payload?.response.data;
    });
    builder.addCase(forgotPassword.rejected, (state, err) => {
      state.loading = false;
      state.forgotPasswordError = err?.payload?.message;
    });

    builder.addCase(ResetPassword.pending, (state) => {
      state.loading = true;
      state.resetPasswordDetail = {};
      state.resetPasswordError = '';
    });
    builder.addCase(ResetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.resetPasswordDetail = action.payload?.response.data;
    });
    builder.addCase(ResetPassword.rejected, (state, err) => {
      state.loading = false;
      state.resetPasswordError = err?.payload?.message;
    });

    builder.addCase(acceptTerms.pending, (state) => {
      state.loading = true;
      state.acceptTermsDetail = {};
      state.acceptTermsError = '';
    });
    builder.addCase(acceptTerms.fulfilled, (state, action) => {
      state.loading = false;
      state.acceptTermsDetail = action.payload?.response.data;
      state.userDetails = { ...state.userDetails, terms_accepted: true };
    });
    builder.addCase(acceptTerms.rejected, (state, err) => {
      state.loading = false;
      state.acceptTermsError = err?.payload?.message;
    });
  },
});

export const {
  reset,
  logout,
  loggedUserInfo,
  setBusPartner,
  resetForgotPassword,
  resetResetPassword,
} = authSlice.actions;

export default authSlice.reducer;
