import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
  button {
    font-size: 25px;
    font-weight: 700;
    line-height: 32.45px;
    background-color: #ededed;
    border-radius: 100px;
    color: #000000;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 12px;
    box-shadow: none;
  }
  button:hover {
    background-color: transparent;
  }
  h2 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    line-height: normal;
  }
`;
export const PartnerFormWrapp = styled.div`
  margin-top: 54px;
  padding-left: 75px;
  padding-right: 116px;
  @media (max-width: 768px) {
    padding: 15px;
  }
  .formWrapp {
    display: flex;
    gap: 40px;
    -webkit-box-flex-wrap: nowrap;
    flex-wrap: nowrap;
    @media (max-width: 768px) {
      -webkit-box-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .MuiInputBase-input {
      padding-top: 12.5px;
      padding-bottom: 12.5px;
    }
    fieldset {
      border: 1px solid ${theme.colors.borderColor};
      border-radius: 6px;
    }
    &:not(:last-child) {
      margin-bottom: 34px;
    }
  }
`;
export const Step1 = styled.div``;
export const Step2 = styled.div`
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  .titleStyle {
    border: 1.5px solid #b9b9b9;
    border-radius: 11px;
    padding: 20px 30px;
    margin: 30px 0;
    @media (max-width: 768px) {
      padding: 20px 10px;
    }
  }
`;
export const CheckBoxSelect = styled.div`
  padding-right: 15px;
  .MuiFormControlLabel-root {
    margin: 0;
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      color: ${theme.colors.black};
      letter-spacing: -0.472px;
      margin-left: 10px;
      position: relative;
      top: 2px;
      width: calc(100% - 35px);
    }
  }
  input[type='radio'],
  input[type='checkbox'] {
    margin: 0;
    appearance: none;
    width: 25px;
    height: 25px;
    border: 1px solid ${theme.colors.primary};
    border-radius: 100px;
    position: relative;
    padding: 0;
    &:checked {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 15px;
        height: 15px;
        border-radius: 100px;
        background: ${theme.colors.primary};
        margin: auto;
      }
    }
  }
`;

export const TripInfo = styled.div``;
export const MainTripBox = styled.div`
  &:not(:first-of-type) {
    padding-left: 100px;
    @media screen and (max-width: 1400px) {
      padding-left: 50px;
    }
    @media screen and (max-width: 767px) {
      padding-left: 25px;
    }
  }
  &:first-of-type {
    .button {
      padding-left: 100px;
      @media screen and (max-width: 1400px) {
        padding-left: 50px;
        @media screen and (max-width: 767px) {
          padding-left: 25px;
        }
      }
    }
  }
`;

export const AddStopButton = styled.div`
  text-align: center;
  button.MuiButton-contained {
    border-radius: 26.5px;
    background: ${theme.colors.green};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    padding: 8px 15px;
    text-transform: none;
    &:before {
      content: '+';
      font-size: 35px;
      line-height: 30px;
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }
`;

export const BottomInfo = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const BottomContentLeft = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
  .MuiFormGroup-root {
    margin-top: 32px;
  }
  input {
    text-align: right;
    padding: 12px 22.5px 12px 12.5px;
  }
  fieldset {
    border-radius: 6px;
    border: 1px solid ${theme.colors.grey};
  }
`;
export const BottomContentRight = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    justify-content: flex-end;
    margin-top: 30px;
  }
  .MuiCircularProgress-indeterminate {
    margin-right: 10px;
  }
  button.MuiButton-contained {
    border-radius: 6px;
    background: ${theme.colors.lightblack};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    letter-spacing: -0.472px;
    padding: 13px 15px;
    line-height: normal;
    svg {
      fill: ${theme.colors.white};
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
  }
`;

export const InputField = styled.div`
  width: 112px;
`;

export const SubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  @media screen and (max-width: 767px) {
    justify-content: flex-end;
    margin-top: 30px;
  }
  .MuiCircularProgress-indeterminate {
    margin-right: 10px;
  }
  button.MuiButton-contained {
    border-radius: 100px;
    align-item: right;
    background: ${theme.colors.lightblack};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    letter-spacing: -0.472px;
    padding: 13px 15px;
    line-height: normal;
    svg {
      fill: ${theme.colors.white};
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
  }
`;
export const TermsConditions = styled.div`
  max-width: 380px;
  margin-left: auto;
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
  a.MuiLink-root {
    color: #195eb0;
    font-weight: 700;
  }
`;
export const MainBox = styled.div`
  max-width: 1024px;
  margin: 139px auto 40px;
  border-radius: 11px;
  border: 1px solid ${theme.colors.grey};
  box-sizing: border-box;
  ${theme.breakPoint.tablet} {
    margin: 40px auto 40px;
    border-radius: 30px;
    padding: 0 20px 30px;
  }
`;

export const Title = styled.div`
  padding: 89px 0;
  text-align: center;
  ${theme.breakPoint.tablet} {
    padding: 40px 0 20px;
  }
  h1 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.8px;
    color: ${theme.colors.black};
    padding-left: 15px;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    color: ${theme.colors.black};
    max-width: 810px;
    margin: 0 auto;
  }
`;
export const CheckMark = styled.div`
  width: 30px;
  height: 30px;
  background: ${theme.colors.green};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HeadingWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
  .requestSuccessModal {
    dispaly: flex !important;
    flex-direction: row;
  }
  @media (max-width: 768px) {
    flex-direction: column !important;
  }
`;
export const FormBox = styled.div`
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${theme.colors.borderColor};
  max-width: 847px;
  border-radius: 11px;
  padding: 14px 31px 36px 31px;
  .boxHeading {
    font-size: 25px;
    font-weight: 700;
    line-height: 32.45px;
    letter-spacing: -0.02em;
    color: ${theme.colors.black};
  }
`;

export const Heading = styled.div`
  font-size: 50px;
  font-weight: 700;
  line-height: 32.45px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${theme.colors.black};
  margin: 42px 0;
`;
export const SubmitWrapper = styled.div`
  margin-top: 78px;
  max-width: 847px;
  margin-left: auto;
  margin-right: auto;
`;
