import { createSlice } from '@reduxjs/toolkit';
import {
  getHistory,
  getHistoryDetail,
  updateRequestStatus,
} from '../reqhistory';

const initialState = {
  loading: false,
  requestHistoryDetail: {},
  requestHistoryError: '',
  requestHistoryInfo: {},
  requestHistoryInfoError: '',
};

const reqHistorySlice = createSlice({
  name: 'reqhistory',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getHistory.pending, (state) => {
      state.loading = true;
      state.requestHistoryDetail = {};
      state.requestHistoryError = '';
    });
    builder.addCase(getHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.requestHistoryDetail = action.payload?.response?.data?.data;
    });
    builder.addCase(getHistory.rejected, (state, err) => {
      state.loading = false;
      state.requestHistoryError = err?.payload;
    });

    builder.addCase(getHistoryDetail.pending, (state) => {
      state.loading = true;
      state.requestHistoryInfo = {};
      state.requestHistoryInfoError = '';
    });
    builder.addCase(getHistoryDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.requestHistoryInfo = action.payload?.response?.data?.data;
    });
    builder.addCase(getHistoryDetail.rejected, (state, err) => {
      state.loading = false;
      state.requestHistoryInfoError = err?.payload;
    });

    builder.addCase(updateRequestStatus.pending, (state) => {
      state.loading = true;
      state.updateReqStatusDetail = {};
      state.updateReqStatusError = '';
    });
    builder.addCase(updateRequestStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.updateReqStatusDetail = action.payload?.response?.data;
    });
    builder.addCase(updateRequestStatus.rejected, (state, err) => {
      state.loading = false;
      state.updateReqStatusError = err?.payload;
    });
  },
});

export const { reset } = reqHistorySlice.actions;

export default reqHistorySlice.reducer;
