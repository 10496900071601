import { createAsyncThunk } from '@reduxjs/toolkit';
import { callPostAPI } from '../../services/auth';

export const postPriceCalculation = createAsyncThunk(
  'postPriceCalculation',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'online-calculation',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getArrivalTime = createAsyncThunk(
  'getArrivalTime',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'calculate-route',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getArrivalTimeReturn = createAsyncThunk(
  'getArrivalTimeReturn',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'calculate-route',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
