import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/auth';

export const acceptRequest = createAsyncThunk(
  'acceptRequest',
  async (params, { rejectWithValue }) => {
    try {
      const { requestId } = params;
      const response = await callPostAPI({
        route: 'accept-request/' + requestId,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const rejectRequest = createAsyncThunk(
  'rejectRequest',
  async (params, { rejectWithValue }) => {
    try {
      const { requestId } = params;
      const response = await callPostAPI({
        route: 'reject-request/' + requestId,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const clientConfirmation = createAsyncThunk(
  'clientConfirmation',
  async (params, { rejectWithValue }) => {
    try {
      const { requestId, ...newparams } = params;
      const response = await callPostAPI({
        route: 'client-confirmation/' + requestId,
        body: newparams,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRequestClientType = createAsyncThunk(
  'getRequestClientType',
  async (params, { rejectWithValue }) => {
    try {
      const { requestId } = params;
      const response = await callGetAPI({ route: 'client-type/' + requestId });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const buspartnerConfirmation = createAsyncThunk(
  'buspartnerConfirmation',
  async (params, { rejectWithValue }) => {
    try {
      const { requestId, ...newparams } = params;
      const response = await callPostAPI({
        route: 'buspartner-confirmation/' + requestId,
        body: newparams,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
