import { Paper, Typography, Box, Button } from '@mui/material';
import styled from '@emotion/styled';

// Paper container for wrapping content
export const PaperContainer = styled(Paper)({
  padding: '30px',
  borderRadius: '10px',
  backgroundColor: '#eef2ff',
  margin: '15px',
  border: '1px solid #a5b4fc',
});

// Header styling
export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
});

// Badge styling
export const Badge = styled(Typography)({
  display: 'flex', // Ensures content is in a row
  alignItems: 'center', // Centers items vertically
  fontSize: '14px',
  fontWeight: '600',
  marginRight: '8px',
  gap: '4px',
  backgroundColor: '#c7d2fe',
  padding: '6px 8px',
  borderRadius: '24px',
  color: '#4f46e5',
});

// Title styling
export const Title = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '20px',
  marginTop: '15px',
  lineHeight: '1.35rem',
});

// Description styling
export const Description = styled(Typography)({
  margin: '5px 0',
  fontSize: '16px',
  color: '#1e293b',
  lineHeight: '1.25rem',
  marginBottom: '20px',
});

export const PrimaryButton = styled(Button)(({ theme }) => ({
  fontWeight: 600, // Sets the font weight to 600
  textTransform: 'none', // Removes uppercase text transformation
  backgroundColor: '#4f46e5', // Sets the background color to purple
  boxShadow: 'none',
  borderRadius: '10px',
  marginRight: '10px',
  color: '#ffffff', // Sets the text color to white
  '&:hover': {
    backgroundColor: '#3730a3', // Darker purple on hover
    boxShadow: 'none',
  },
}));

export const SecondaryButton = styled(Button)(({ theme }) => ({
  fontWeight: 600, // Sets the font weight to 600
  textTransform: 'none', // Removes uppercase text transformation
  backgroundColor: '#ffffff', // Sets the background color to white
  color: '#4f46e5', // Sets the text color to purple
  boxShadow: 'none',
  border: '1px solid #4f46e5', // Sets a purple border
  borderRadius: '10px', // Sets the border radius to 10px
  marginRight: '10px', // Adds right margin
  '&:hover': {
    backgroundColor: '#f3f4f6', // Sets a light grey background on hover
    boxShadow: 'none', // Removes box shadow on hover
  },
}));

// Additional components from your previous references

export const ActionButtons = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
});

export const BlockTitle = styled(Typography)({
  fontSize: '20px',
  fontWeight: '600',
  marginBottom: '30px',
});

export const Buttons = styled(Box)({
  display: 'flex',
  gap: '10px',
});

export const FormAction = styled(Box)({
  marginTop: '20px',
});

export const FormField = styled(Box)({
  marginBottom: '15px',
});

export const OfferFormCol = styled(Box)({
  width: '100%',
});

export const OfferedText = styled(Typography)({
  fontSize: '14px',
  color: '#555',
  marginBottom: '10px',
});

export const TripBox = styled(Box)({
  borderRadius: '10px',
  padding: '15px',
  marginBottom: '15px',
});

